html, body {
  font-family: Noto Sans JP, 游ゴシック体, Yu Gothic, YuGothic, ヒラギノ角ゴシック Pro, Hiragino Kaku Gothic Pro, メイリオ, Meiryo, Osaka, ＭＳ Ｐゴシック, MS PGothic, sans-serif !important;
}

.navbar .navbar-nav .nav-item .nav-link {
  color: #000;
  font-size: 14px;
}

.navbar .navbar-nav .nav-item .nav-link:hover, .navbar .navbar-nav .nav-item .nav-link.active {
  color: #0068b0;
}

.gap-4px {
  gap: 4px;
}

.gap-8 {
  gap: .5rem;
}

.gap-12 {
  gap: .75rem;
}

.gap-16 {
  gap: 1rem;
}

.gap-20 {
  gap: 1.25rem;
}

.gap-24 {
  gap: 1.5rem;
}

.gap-32 {
  gap: 2rem;
}

.gap-40 {
  gap: 2.5rem;
}

.gap-48 {
  gap: 3rem;
}

.gap-64 {
  gap: 4rem;
}

.gap-72 {
  gap: 4.5rem;
}

.text-inherit {
  color: inherit !important;
}

.text-primary {
  color: #0068b0 !important;
}

.text-16191e {
  color: #16191e !important;
}

.text-3f4855 {
  color: #3f4855 !important;
}

.text-141414 {
  color: #141414 !important;
}

.text-313131 {
  color: #313131 !important;
}

.text-1d2939 {
  color: #1d2939 !important;
}

.text-3a3a3a {
  color: #3a3a3a !important;
}

.text-00365b {
  color: #00365b !important;
}

.text-00365b99 {
  color: #00365b99 !important;
}

.text-ed3f34 {
  color: #ed3f34 !important;
}

.text-1C2124 {
  color: #1c2124 !important;
}

.text-66869d {
  color: #66869d !important;
}

.text-809aad {
  color: #809aad !important;
}

.text-4d728c {
  color: #4d728c !important;
}

.text-335e7c {
  color: #335e7c !important;
}

.bg-none {
  background: none !important;
}

.bg-primary {
  background-color: #0068b0 !important;
}

.bg-f6f9fb {
  background-color: #f6f9fb !important;
}

.bg-e5f0f7 {
  background-color: #e5f0f7 !important;
}

.bg-60a5d7 {
  background-color: #60a5d7 !important;
}

.bg-f0f6ff {
  background-color: #f0f6ff !important;
}

.bg-272e32 {
  background-color: #272e32 !important;
}

.bg-00365b0d {
  background-color: #00365b0d !important;
}

.bg-00365b {
  background-color: #00365b !important;
}

.bg-ed3f34 {
  background-color: #ed3f34 !important;
}

.bg-f8fbff {
  background-color: #f8fbff !important;
}

.mt-8 {
  margin-top: .5rem;
}

.mt-10 {
  margin-top: .625rem !important;
}

.mt-12 {
  margin-top: .75rem !important;
}

.mt-16 {
  margin-top: 1rem;
}

.mt-18 {
  margin-top: 1.125rem;
}

.mt-20 {
  margin-top: 1.25rem;
}

.mt-24 {
  margin-top: 1.5rem !important;
}

.mt-32 {
  margin-top: 2rem;
}

.mt-40 {
  margin-top: 2.5rem;
}

.mt-48 {
  margin-top: 3rem !important;
}

.mt-64 {
  margin-top: 4rem;
}

.mt-72 {
  margin-top: 4.5rem;
}

.mt-200 {
  margin-top: 12.5rem;
}

.mb-8px {
  margin-bottom: 8px;
}

.mb-20 {
  margin-bottom: 1.25rem;
}

.mr-12 {
  margin-right: .75rem;
}

.mr-20 {
  margin-right: 1.25rem;
}

.mr-24 {
  margin-right: 1.5rem;
}

.my-8 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.w-16 {
  width: 1rem;
}

.w-20 {
  width: 1.25rem;
}

.w-312 {
  width: 19.5rem;
}

.w-320 {
  width: 20rem;
}

.h-16 {
  height: 1rem;
}

.icon-18 {
  width: 1.125rem;
  height: 1.125rem;
}

.icon-20 {
  width: 1.25rem;
  height: 1.25rem;
}

.icon-24 {
  width: 1.5rem;
  height: 1.5rem;
}

.icon-28 {
  width: 1.75rem;
  height: 1.75rem;
}

.icon-32 {
  width: 2rem;
  height: 2rem;
}

.icon-48 {
  width: 3rem;
  height: 3rem;
}

.w-p-20 {
  width: 20%;
}

.after-none:after {
  content: none;
}

.p-12 {
  padding: .75rem;
}

.p-16 {
  padding: 1rem;
}

.p-24 {
  padding: 1.5rem;
}

.p-32 {
  padding: 2rem;
}

.p-40 {
  padding: 2.5rem;
}

.px-4px {
  padding-left: 4px;
  padding-right: 4px;
}

.px-8 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-10 {
  padding-left: .625rem;
  padding-right: .625rem;
}

.px-12 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-16 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-20 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-24 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-32 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.px-40 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.px-48 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.px-56 {
  padding-left: 3.5rem;
  padding-right: 3.5rem;
}

.px-64 {
  padding-left: 4rem;
  padding-right: 4rem;
}

.py-4px {
  padding-top: 4px;
  padding-bottom: 4px;
}

.py-6 {
  padding-top: .375rem;
  padding-bottom: .375rem;
}

.py-8 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-12 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-16 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-20 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.py-24 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.p-t-24 {
  padding-top: 1.5rem !important;
}

.p-b-14 {
  padding-bottom: .875rem !important;
}

.p-b-20 {
  padding-bottom: 1.25rem !important;
}

.fw-400 {
  font-weight: 400;
}

.fw-700 {
  font-weight: 700;
}

.fs-12 {
  font-size: .75rem;
}

.fs-14 {
  font-size: .875rem !important;
}

.fs-16 {
  font-size: 1rem;
}

.fs-18 {
  font-size: 1.125rem;
}

.fs-20 {
  font-size: 1.25rem !important;
}

.fs-22 {
  font-size: 1.375rem;
}

.fs-24 {
  font-size: 1.5rem;
}

.fs-28 {
  font-size: 1.75rem;
}

.fs-30 {
  font-size: 1.875rem;
}

.fs-32 {
  font-size: 2rem;
}

.fs-36 {
  font-size: 2.25rem;
}

.fs-42 {
  font-size: 2.625rem;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.b-r-0 {
  border-radius: 0 !important;
}

.b-r-4 {
  border-radius: .25rem;
}

.b-r-6 {
  border-radius: 6px;
}

.b-r-8 {
  border-radius: 8px;
}

.b-r-10 {
  border-radius: .625rem;
}

.b-r-12 {
  border-radius: .75rem;
}

.b-r-16 {
  border-radius: 1rem;
}

.b-r-20 {
  border-radius: 1.25rem;
}

.b-r-32 {
  border-radius: 2rem;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.b-transparent {
  border: 1px solid #0000;
}

.b-white {
  border: 1px solid #fff;
}

.img-cover {
  object-fit: cover;
}

.top-12 {
  top: .75rem !important;
}

.top-16 {
  top: 1rem;
}

.top-20 {
  top: 1.25rem;
}

.top-28 {
  top: 1.75rem;
}

.top-32 {
  top: 2rem !important;
}

.top-36 {
  top: 2.25rem;
}

.top-48 {
  top: 3rem !important;
}

.top-150 {
  top: 9.375rem;
}

.top-p-200 {
  top: 200% !important;
}

.left-0 {
  left: 0;
}

.left-12 {
  left: .75rem;
}

.left-28 {
  left: 1.75rem;
}

.right-0 {
  right: 0;
}

.right-12 {
  right: .75rem;
}

.right-28 {
  right: 1.75rem;
}

.flex-0 {
  flex: 0;
}

.flex-1 {
  flex: 1;
}

.ml-auto {
  margin-left: auto !important;
}

.ml-8 {
  margin-left: .5rem;
}

.mx-24 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.m-l-12 {
  margin-left: .75rem;
}

.ml-n12 {
  margin-left: -.75rem;
}

.m-l-16 {
  margin-left: 1rem;
}

.p-l-12 {
  padding-left: .75rem;
}

.pl-0 {
  padding-left: 0;
}

.pl-16 {
  padding-left: 1rem;
}

.pl-24 {
  padding-left: 1.5rem;
}

.p-l-28 {
  padding-left: 1.75rem !important;
}

.p-e-none {
  pointer-events: none;
}

.text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.multiline-ellipsis-2 {
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  word-break: break-all;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.multiline-ellipsis-3 {
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
  word-break: break-all;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.blur-20 {
  filter: blur(1.25rem);
}

.line-h-20 {
  line-height: 1.25rem;
}

.line-h-24 {
  line-height: 1.5rem;
}

.icon-12 {
  width: .75rem;
  height: .75rem;
}

.icon-14 {
  width: .875rem;
  height: .875rem;
}

.icon-16 {
  width: 1rem;
  height: 1rem;
}

.lh-28 {
  line-height: 1.75rem;
}

.list-none {
  list-style: none;
}

.text-nowrap {
  white-space: nowrap;
}

/*# sourceMappingURL=index.40c87218.css.map */
